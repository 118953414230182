import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm$template, _vm$template2, _vm$manager, _vm$manager2, _vm$manager3, _vm$manager4;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.ConsultationSection
  }, [_c('div', {
    class: _vm.$style.top
  }, [_c('div', {
    class: _vm.$style.title
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])]), _vm._v(" "), _c('div', {
    class: _vm.$style.body
  }, [_c('div', [_c('div', {
    class: _vm.$style.promo
  }, [_c('div', {
    class: _vm.$style.left
  }, [_c('div', {
    class: _vm.$style.title,
    domProps: {
      "innerHTML": _vm._s((_vm$template = _vm.template) === null || _vm$template === void 0 ? void 0 : _vm$template.title)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.description,
    domProps: {
      "innerHTML": _vm._s((_vm$template2 = _vm.template) === null || _vm$template2 === void 0 ? void 0 : _vm$template2.text)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.items
  }, [_c('div', {
    class: _vm.$style.item
  }, [_c('div', {
    class: _vm.$style.itemLabel
  }, [_vm._v("\n                Телефон\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.itemText
  }, [_vm.isMobile ? _c('a', {
    attrs: {
      "href": "tel:".concat((_vm$manager = _vm.manager) === null || _vm$manager === void 0 ? void 0 : _vm$manager.phone)
    }
  }, [_c('PhoneOutput', {
    attrs: {
      "input": (_vm$manager2 = _vm.manager) === null || _vm$manager2 === void 0 ? void 0 : _vm$manager2.phone,
      "without-parenthesis": ""
    }
  })], 1) : _c('PhoneOutput', [_vm._v("\n                  :input=\"manager?.phone\"\n                  without-parenthesis\n                ")])], 1)]), _vm._v(" "), _c('div', {
    class: _vm.$style.item
  }, [_c('div', {
    class: _vm.$style.itemLabel
  }, [_vm._v("\n                Режим работы\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.itemText
  }, [_vm._v("\n                " + _vm._s((_vm$manager3 = _vm.manager) === null || _vm$manager3 === void 0 ? void 0 : _vm$manager3.workSchedule) + "\n              ")])])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.right
  }, [_c('div', {
    class: _vm.$style.consultant
  }, [_c('div', {
    class: _vm.$style.consultantName
  }, [_vm._v("\n              " + _vm._s(_vm.managerName) + "\n            ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.consultantDescription
  }, [_vm._v("\n              " + _vm._s((_vm$manager4 = _vm.manager) === null || _vm$manager4 === void 0 ? void 0 : _vm$manager4.position) + "\n            ")])]), _vm._v(" "), _c('div', {
    class: _vm.$style.img
  }, [_c('img', {
    attrs: {
      "src": _vm.managerPhoto,
      "alt": _vm.managerName
    }
  })])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };